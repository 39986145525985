import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import Bio from "../components/bio"
import useSiteMetadata from "../components/use-site-metadata"
import AddThis from "../components/addthis"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import helper from "../utils/helper"

import config from "../../config"

const BlogPostTemplate = ({ location, data: { previous, next, post } }) => {
  const { siteUrl } = useSiteMetadata()
  const locationHref = location.href

  const readingTime = post.readingTime.minutes.toFixed(1)
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }

  let postMeta = []

  if (featuredImage.fluid) {
    const width = featuredImage.fluid.maxWidth;
    const height = featuredImage.fluid.maxHeight;

    postMeta = [
      { name: "og:image:width", content: width },
      { name: "og:image:height", content: height },
    ]
  }

  const imageSrc = `${siteUrl}${featuredImage?.fluid?.src}`

  useEffect(() => {
    helper.highlightCode()
  })

  let isDevMode = false

  if (typeof document !== "undefined") {
    if (document.cookie.match(/^(.*;)?\s*isDevModeEnabled\s*=\s*[^;]+(.*)?$/)) {
      isDevMode = true
    }
  }

  let currentUrl = new URL("http://localhost")
  let wordpressUrl = ""

  if (locationHref) {
    try {
      currentUrl = new URL(locationHref)
      wordpressUrl = `${config.SITE_META_URL.replace(
        "https://",
        "https://wordpress."
      )}${currentUrl.pathname}`
    } catch (e) {
      // I'm being lazy
    }
  }

  return (
    <Layout locationHref={locationHref}>
      <SEO
        meta={postMeta}
        title={post.title}
        description={post.seo.metaDesc}
        url={location.href}
        imageSrc={imageSrc}
      />
      <Bio />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <nav className="blog-post-nav">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.uri} rel="prev">
                    ← {parse(previous.title)}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.uri} rel="next">
                    {parse(next.title)} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
          {isDevMode && (
            <div>
              <ul>
                <li>
                  <a href={wordpressUrl} target="_blank" rel="noreferrer">
                    Edit on wordpress
                  </a>
                </li>
              </ul>
            </div>
          )}
          <h1 itemProp="headline">{parse(post.title)}</h1>
          <div className="blog-post-date">{post.date}</div>
          <div className="blog-post-readingtime">
            <span className="blog-post-readingtime-label">Reading Time:</span>{" "}
            {readingTime} minutes
          </div>
          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.fluid && (
            <Image
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
              style={{ marginBottom: 20 }}
            />
          )}
        </header>
        {!!post.content && (
          <section itemProp="articleBody">
            {parse(post.content, { trim: true })}
          </section>
        )}
        <hr />
        <footer>
          <div id="webmentions"></div>          
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <AddThis />
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      readingTime {
        minutes
      }
      seo {
        metaDesc
      }
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
